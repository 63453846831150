<script setup lang="ts">
import { uuid } from '~/utilities/uuid';
import { useSiteStore } from '~/stores/site.store';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';
import ProgressBar from 'primevue/progressbar';
import type {
  severity,
  position,
} from '~/interfaces/dto/general/toast-messages';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline';
import Button from './button.vue';

const site = useSiteStore();
const toast = useToast();

interface ToastProps {
  type: severity;
  heading: string;
  message: string;
  count?: number;
  position?: position;
  timeout?: number;
  enabled: boolean;
}
const props = withDefaults(defineProps<ToastProps>(), {
  type: 'success',
  heading: '',
  message: '',
  count: 1,
  position: 'br',
  timeout: 3000,
  enabled: false
});

const computedPosition = computed(() => {
  switch (props.position) {
    case 'tl':
      return 'top-left';
    case 'bl':
      return 'bottom-left';
    case 'br':
      return 'bottom-right';
    case 'c':
      return 'center';
    case 'tr':
      return 'top-right';
    case 'bc':
      return 'bottom-center';
    case 'tc':
      return 'top-center';
  }
});
const determineIcon = computed<any>((): any => {
  switch (props.type) {
    case 'success':
      return CheckCircleIcon;
    case 'danger':
      return XCircleIcon;
    case 'warning':
      return ExclamationCircleIcon;
    default:
      return CheckCircleIcon;
  }
});
const determineIconBg = computed<string>((): string => {
  switch (props.type) {
    case 'success':
      return 'text-primary-blue-400';
    case 'danger':
      return 'text-error-500';
    case 'warning':
      return 'text-warning-500';
    default:
      return 'text-primary-blue-400';
  }
});
const progressBars = ref({});
const intervals = ref({});
const smallScreen = useMatchMedia('(max-width: 479px)');

function createInterval(id: string) {
  progressBars.value[id] = 100;
  intervals.value[id] = setInterval(() => {
    progressBars.value[id] = progressBars.value[id] - 1;
  }, props.timeout / 100);
}
function clearProgressInterval(id: string) {
  clearInterval(intervals.value[id]);
  site.toggleSiteNotice({
    heading: '',
    message: '',
    severity: 'danger',
    enabled: false,
    timeout: 3000,
  });
  delete progressBars.value[id];
}

function closeToaster(id: string) {
  toast.remove(id);
}

watch(
  () => props,
  () => {
    const id = uuid();
    if (props.message || props.heading) {
      toast.add({
        severity: props.type,
        summary: props.heading,
        detail: props.message,
        group: props.position,
        life: props.timeout,
        closable: true,
        id,
      });
      createInterval(id);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
<template>
  <Toast
    :group="props.position"
    :position="computedPosition"
    unstyled
    close-icon="hidden"
    @life-end="(e) => clearProgressInterval(e.message.id)"
    class="bg-white dark:bg-dark-800 rounded-md w-72"
    :pt="{
      buttonContainer: {
        class: 'hidden'
      }
    }"
  >
    <template #message="slotProps">
      <div class="flex place-content-between bg-base text-base-priority">
        <div class="flex place-items-center p-2 ">
          <div  
            :class="[determineIconBg]"
            class="w-8 h-8 font-bold flex-center rounded-md mr-2"
          >
            <component :is="determineIcon" :key="props.type" class="w-8 font-extrabold" />
          </div>
          <div class="font-bold">
            {{ slotProps.message.summary }}
          </div>
        </div>
        
        <Button
          type="secondary-alternative"
          size="small"
          rounding="md"
          @click="closeToaster(slotProps.message.id)"
          class="m-2"
        >
          <XMarkIcon />
        </Button>
      </div>
      <div class="px-3 py-1 text-center text-base-priority">
        {{ slotProps.message.detail }}
      </div>
      <ProgressBar
      style="height: 6px"
      :class="['rounded-full my-0 mt-2 bg-light-200 dark:bg-dark-800', props.type]"
      :value="progressBars[slotProps.message.id]"
      :show-value="false"
      :pt="{
        value: {
          class: [
            'bg-primary-blue-400', 
            'rounded-full',
            'transition-all',
            'duration-200'
          ],
          style: [
            'height: 6px'
          ]
        }
      }"
    >
    </ProgressBar>
    </template>
  </Toast>
</template>
